import React from 'react';

import 'moment/locale/ru';
import locale from 'antd/lib/locale/ru_RU';
import 'antd/dist/antd.css';
import './App.css';

import eruda 	from 'eruda';

import { Layout, ConfigProvider, notification } from 'antd';
import {   } from '@ant-design/icons';

//@ Служебные
import GlobLoadpage 		      from './components/Global/Loadpage';
import GlobHeader 		           from './components/Global/Header';
import Dev 		                           from './components/Panels/Dev';

//@ Панели
import Nohash 		                           from './components/Panels/Nohash';
import Send 		                          from './components/Panels/Send';

const { Content } = Layout;

const notif = (type,title, text) => {
  notification[type]({
    message: title,
    description:text,
    placement:'bottomRight',
  });
};

class App extends React.Component {
  constructor(props) {
		super(props);

    this.state = {
      auth: 0,
      error: ['',''],
      loading: true,
      moreload: true,
      modal: '',
      api: 'https://clinic.rus2.ru/km/36-2/user.php',
      hash: '',
      reviews: [],
      info: {}
    };
  }

  //* Вызов сразу после рендера
  async componentDidMount() {
    var search = window.location.search;
    var hash = String(new URLSearchParams(search).get('u'));
    if (hash !== 'null' && hash.length > 0) {
      var v = {};
      v['method'] = 'user_get';
      v['hash'] = hash;
      this.setState( { hash:hash } );
      await this.api(v,true);
    } else {
      this.setState( { auth:1 } );
    }
    var debug = String(new URLSearchParams(search).get('debug'));
    if (debug !== 'null' && debug === 'true') {
      let el = document.createElement('div');
			document.body.appendChild(el);
      
      eruda.init({
				container: el,
				useShadowDom: true,
				autoScale: true
			});
    }
  }

  //* POST запрос
  postRequest(url, data) {
    return fetch(url, {
      credentials: 'same-origin',
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
    })
    .then(response => response.json())
  }

  //* Получение информации
  async api(v,s) {
    if (s) {
      await this.setState( { loading: true, moreload:true, modal:'' } );
    } else {
      await this.setState({ moreload:true });
    }
    
    await this.postRequest(`${this.state.api}`, v)
    .then(async json => {
      var data = json;
      if(data.code) {
        //* Методы
        switch(v['method']) {
          //@ Отзыв
          case 'user_get':
            var reviews 		= ( data.reviews ) ? data.reviews : [];
            await this.setState( { reviews:reviews, auth:2 } );
            break;
          case 'user_send':
            var info 		= ( data.info ) ? data.info : {};
            await this.setState( { info:info } );
            break;

          default: break;
        }
      }
      if(data.error) {
        await this.setState( { error: data.error } );
        notif('error',`${data.error[0]}`,`${data.error[1].length > 0 ? `${data.error[1]}`:''}`);
        if (data.error[2] === '667') {
          await this.setState( {auth: 1} );
        }
      }
    })
    .catch( async err => {
      await this.setState( { error: ['Проблемы со связью',''] } );	//Проблемы со связью
    });
    if (s) {
      await this.setState( { loading: false, moreload: false } );
    } else {
      await this.setState({ moreload:false });
    }
  }

  async timesuccess () {
    await this.setState( { success:true } );
    await setTimeout(() => this.setState( { success:false } ), 2 * 1000);
  };

  //* Сортировка
  htc = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  //* Случайный цвет
  randcolor() {
    var arr = ['#cc0029', '#E14015', '#f56a00', '#FA9500', '#ffbf00', '#9EB51E', '#3caa3c', '#1EA675', '#00a2ae', '#42aaff', '#2E82E8', '#1959d1', '#7265e6'];
    var rand = Math.floor(Math.random() * arr.length);
    return arr[rand];
  }

  //* Модальные окна
  async modal(t) {
    this.setState( { modal:t } );
  }

  decnum (a,b) { let cases=[2,0,1,1,1,2];return b[(a%100>4 && a%100<20)?2:cases[(a%10<5)?a%10:5]]; }

  //* Рендер
  render() {
    const spin = <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>;

    return (
      <ConfigProvider locale={locale}>
        <Layout style={{ minHeight: "100vh" }}>
          {this.state.auth === 0 && <GlobLoadpage />}
          {this.state.auth === 1 && 
            <React.Fragment>
              <GlobHeader state={this.state} />
              <Content>
                <Nohash />
              </Content>
            </React.Fragment>}
          {this.state.auth === 2 &&
            <React.Fragment>
              <GlobHeader state={this.state} />
              <Content>
                {!this.state.loading ?
                  <div className="site-layout-background" >
                    <Send state={this.state} setState={this.setState.bind(this)} modal={this.modal} api={this.api.bind(this)} />
                  </div>
                  :
                  spin
                }
              </Content>
            </React.Fragment>
          }
        </Layout>
      </ConfigProvider>
    );
  }
}

export default App;
